import React from 'react';
import { Checkbox, FavouriteHoverIconButton, Stack } from '@talentmesh/core';
import { useTheme } from '@mui/material/styles';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import canSelectCandidate from '../../Utils/CanSelectCandidate';
import { CandidateOverview } from '../../../../../Models/CandidateOverview';

interface FavoriteCellProps {
    tabValue: string;
    candidate: CandidateOverview;
}

function FavoriteCell({ tabValue, candidate }: FavoriteCellProps): JSX.Element {
    const theme = useTheme();
    const { setSelected, selected, saveFavoriteAsync } = useCandidatesOverviewContext();

    const { candidateId, isFavorite } = candidate;

    const saveFavoriteHandlerAsync = async () => {
        await saveFavoriteAsync(candidateId, !isFavorite);
    };

    return (
        <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Checkbox
                disableRipple
                checked={selected.find((x) => x.candidateId === candidateId) !== undefined}
                disabled={tabValue !== 'Open' || !canSelectCandidate(candidate)}
                onChange={(event) => {
                    const { checked } = event.target;

                    const nextState = selected.filter((x) => x.candidateId !== candidateId);
                    if (checked) {
                        nextState.push(candidate);
                    }

                    setSelected(nextState);
                }}
            />
            {isFavorite && (
                <FavouriteHoverIconButton
                    onClick={saveFavoriteHandlerAsync}
                    sx={{ padding: 0, color: theme.palette.primary.dark }}
                    disableRipple
                />
            )}
        </Stack>
    );
}

export default FavoriteCell;
