enum CandidateSortByEnum {
    TalentScoreDesc,
    TalentScoreAsc,
    SkillsScoreDesc,
    SkillsScoreAsc,
    AptitudeScoreDesc,
    AptitudeScoreAsc,
    PersonalityScoreDesc,
    PersonalityScoreAsc,
    EmotionalIntelligenceScoreDesc,
    EmotionalIntelligenceScoreAsc,
}

export default CandidateSortByEnum;
