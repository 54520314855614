import {
    AutocompleteOption,
    DirectReportsType,
    EmploymentTypes,
    WorkArrangements,
    WorkExperienceType,
} from '@talentmesh/core';
import autoMapping from '../../../../../Utils/autoMapping';
import { isNullOrWhiteSpace } from '../../../../../Utils/Helpers';
import LocationDetails, {
    DefaultLocationDetails,
    locationDetailsDeepEquals,
} from '../../../../../Models/LocationDetails';
import { DefaultJobDetails, JobDetails } from '../../Models/JobDetails';
import { DefaultJobDetailsDTO, JobDetailsDTO } from '../../../../../DTO/Assessments/JobDetailsDTO';
import { Currency } from '../../../../../Models/Currency';
import { JobAdLanguage } from '../../../../../Models/JobAdLanguage';
import { defaultLanguage, languages } from '../../../../../Utils/JobAdLanguage/JobAdLanguages';

export enum JobDetailsFieldTypes {
    AssessmentName = 'assessmentName',
    WorkArrangement = 'workArrangement',
    JobLocation = 'jobLocation',
    JobCategoryId = 'jobCategoryId',
    JobFunctionId = 'jobFunctionId',
    WorkExperience = 'workExperience',
    EmploymentType = 'employmentType',
    DirectReports = 'directReports',
    YearlySalaryFrom = 'yearlySalaryFrom',
    YearlySalaryTo = 'yearlySalaryTo',
    Currency = 'currency',
    DoNotDisplaySalary = 'doNotDisplaySalary',
    JobDescriptionLanguage = 'jobDescriptionLanguage',
}

export const MAX_SALARY = 9999999999999998; // this is maximum number allowed by eslint

export interface JobDetailsFormValues {
    assessmentName: string;
    workArrangement: WorkArrangements;
    jobLocation: LocationDetails | null;
    jobFunctionId: string;
    jobCategoryId: string;
    workExperience: WorkExperienceType;
    employmentType: EmploymentTypes;
    directReports: DirectReportsType;
    yearlySalaryFrom: string;
    yearlySalaryTo: string;
    currency: AutocompleteOption;
    doNotDisplaySalary: boolean;
    jobDescriptionLanguage: JobAdLanguage;
}

const DefaultJobDetailsFormValues: JobDetailsFormValues = {
    assessmentName: '',
    workArrangement: 'OnLocation',
    jobLocation: null,
    jobCategoryId: '',
    jobFunctionId: '',
    workExperience: 'EntryLevel',
    employmentType: 'FullTime',
    directReports: 'None',
    yearlySalaryFrom: '',
    yearlySalaryTo: '',
    currency: { id: '', label: '' },
    doNotDisplaySalary: false,
    jobDescriptionLanguage: defaultLanguage,
};

export function jobDetailsModel2FormValues(jobCategoryId: string, model: JobDetails): JobDetailsFormValues {
    const form = autoMapping(model, DefaultJobDetailsFormValues);

    const { name, jobFunctionId, locationDetails } = model;

    form.assessmentName = name;
    form.jobCategoryId = jobCategoryId;
    form.jobFunctionId = jobFunctionId > 0 ? `${jobFunctionId}` : '';

    if (locationDetails !== DefaultLocationDetails) {
        form.jobLocation = locationDetails;
    }

    return form;
}

export function jobDetailsFormValues2Model(values: JobDetailsFormValues): JobDetails {
    const model = autoMapping(values, DefaultJobDetails);

    const { assessmentName, jobLocation } = values;
    model.name = assessmentName;
    model.jobFunctionId = +values.jobFunctionId;
    model.locationDetails = jobLocation || DefaultLocationDetails;

    return model;
}

export function getErrorFieldNames(values: JobDetailsFormValues): string[] {
    const fieldNames: string[] = [];
    if (isNullOrWhiteSpace(values.assessmentName)) {
        fieldNames.push(JobDetailsFieldTypes.AssessmentName);
    }

    if (values.jobLocation === null) {
        fieldNames.push(JobDetailsFieldTypes.JobLocation);
    }

    if (Number(values.jobCategoryId) <= 0) {
        fieldNames.push(JobDetailsFieldTypes.JobCategoryId);
    }

    if (Number(values.jobFunctionId) <= 0) {
        fieldNames.push(JobDetailsFieldTypes.JobFunctionId);
    }

    if (!values.doNotDisplaySalary) {
        if (isNullOrWhiteSpace(values.yearlySalaryFrom)) {
            fieldNames.push(JobDetailsFieldTypes.YearlySalaryFrom);
        }

        if (isNullOrWhiteSpace(values.yearlySalaryTo)) {
            fieldNames.push(JobDetailsFieldTypes.YearlySalaryTo);
        }

        if (isNullOrWhiteSpace(values.currency.id)) {
            fieldNames.push(JobDetailsFieldTypes.Currency);
        }
    }

    return fieldNames;
}

export function mapJobDetailsDTO2Model(dto: JobDetailsDTO, currencies: Currency[]): JobDetails {
    const model = autoMapping(dto, DefaultJobDetails);

    if (dto.currency !== null) {
        model.yearlySalaryFrom = String(dto.yearlySalaryFrom);
        model.yearlySalaryTo = String(dto.yearlySalaryTo);
        model.currency = currencies.find((x) => x.id === dto.currency) ?? { id: '', label: '' };
    } else {
        model.doNotDisplaySalary = true;
        model.yearlySalaryFrom = '';
        model.yearlySalaryTo = '';
        model.currency = { id: '', label: '' };
    }

    model.jobDescriptionLanguage =
        languages.find((language) => language.id === dto.jobDescriptionLanguage) ?? defaultLanguage;

    return model;
}

export function mapJobDetailsModel2Dto(model: JobDetails): JobDetailsDTO {
    const dto = autoMapping(model, DefaultJobDetailsDTO);

    const { doNotDisplaySalary, yearlySalaryFrom, yearlySalaryTo, currency, jobDescriptionLanguage } = model;

    if (doNotDisplaySalary) {
        dto.yearlySalaryFrom = null;
        dto.yearlySalaryTo = null;
        dto.currency = null;
    } else {
        dto.yearlySalaryFrom = parseFloat(yearlySalaryFrom);
        dto.yearlySalaryTo = parseFloat(yearlySalaryTo);
        dto.currency = String(currency.id);
    }

    dto.jobDescriptionLanguage = jobDescriptionLanguage.id;

    return dto;
}

export function jobDetailsDeepEqual(left: JobDetails, right: JobDetails): boolean {
    return (
        left.currency.id === right.currency.id &&
        left.directReports === right.directReports &&
        left.doNotDisplaySalary === right.doNotDisplaySalary &&
        left.employmentType === right.employmentType &&
        left.jobFunctionId === right.jobFunctionId &&
        locationDetailsDeepEquals(left.locationDetails as LocationDetails, right.locationDetails as LocationDetails) && // fix it after Anton feature is merged
        left.name === right.name &&
        left.workArrangement === right.workArrangement &&
        left.workExperience === right.workExperience &&
        left.yearlySalaryFrom === right.yearlySalaryFrom &&
        left.yearlySalaryTo === right.yearlySalaryTo &&
        left.jobDescriptionLanguage.id === right.jobDescriptionLanguage.id
    );
}
