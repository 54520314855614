import { Stack, Button, AutocompleteOption } from '@talentmesh/core';
import { ChevronRightIcon } from '@talentmesh/icons';
import { JobAdSkill, JobAdSkillType } from '@talentmesh/job-ad/dist/cjs/Models/JobAdSkill';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import UIStrings from '../../../../../../Utils/UIStrings';
import NextStepButton from '../../../Components/NextStepButton';
import ProcessingState from '../../../../../../Models/ProcessingState';
import { useModifyRecruitmentContext, RecruitmentActionTypes } from '../../../Contexts/ModifyRecruitmentContext';
import useModifyRecruitmentContextHook from '../../../Contexts/useModifyRecruitmentContextHook';
import { JobAdFormValues, jobAdFormValues2Model } from '../JobAdStepUtils';
import JobAdPagePreviewDialog from '../../../../../../Components/JobAdPreview/JobAdPagePreviewDialog';
import { siteUrls } from '../../../../../../AppSettings';
import { getJobCategoryJobFunctionPair } from '../../../../../../Utils/JobFunctionSelectionUtils';
import { useAssessmentSettingsContext } from '../../../../../../Context/AssessmentSettingsContext';
import { useCompanyContext } from '../../../../../../Context/CompanyContext';

function JobAdNavigationButtons(): JSX.Element {
    const action = useModifyRecruitmentContext();
    const { processingState, setJobAd, jobDetails, jobAd } = useModifyRecruitmentContextHook(action);
    const { assessmentSettings } = useAssessmentSettingsContext();
    const { currentCompanyProfile } = useCompanyContext();
    const { values, isValid } = useFormikContext<JobAdFormValues>();
    const [open, setOpen] = useState(false);

    const { companyName, companyIconURL } = currentCompanyProfile;

    const handlePreview = () => {
        setJobAd(jobAdFormValues2Model(values));
        setOpen(true);
    };
    const onClose = () => setOpen(false);

    const skills = [] as JobAdSkill[];
    const mapSkills = (input: AutocompleteOption[], type: JobAdSkillType) => {
        return input.map(
            (item) =>
                ({
                    skillName: item.label,
                    skillType: type,
                }) as JobAdSkill,
        );
    };

    if (jobAd.softSkillsSwitch) {
        skills.push(...mapSkills(jobAd.softSkills, 'SoftSkill'));
    }
    if (jobAd.hardSkillsSwitch) {
        skills.push(...mapSkills(jobAd.hardSkills, 'HardSkill'));
    }

    const getNextButton = () => {
        if (action === RecruitmentActionTypes.Create) {
            // Create recruitment flow
            return (
                <NextStepButton loading={processingState === ProcessingState.Processing} endIcon={<ChevronRightIcon />}>
                    {UIStrings.Next}
                </NextStepButton>
            );
        }

        // Edit job ad flow
        return (
            <NextStepButton loading={processingState === ProcessingState.Processing}>{UIStrings.Update}</NextStepButton>
        );
    };

    return (
        <Stack direction="row" spacing={2}>
            <Button
                variant="outlined"
                onClick={handlePreview}
                disabled={!isValid || processingState === ProcessingState.Processing}
            >
                {UIStrings.PreviewJobAd}
            </Button>
            {getNextButton()}
            <JobAdPagePreviewDialog
                open={open}
                handleClose={onClose}
                jobAd={{
                    ...jobAd,
                    recruitmentId: '',
                    companyName,
                    companyIcon: companyIconURL,
                    isAvailable: false,
                    jobDetails: {
                        ...jobDetails,
                        jobFunctionName: getJobCategoryJobFunctionPair(
                            assessmentSettings.jobCategories,
                            jobDetails.jobFunctionId,
                        ).jobFunctionName,
                        locationDetails: jobDetails.locationDetails,
                        salary: jobDetails.doNotDisplaySalary
                            ? undefined
                            : {
                                  yearlySalaryFrom: +jobDetails.yearlySalaryFrom, // + is used to convert string to number
                                  yearlySalaryTo: +jobDetails.yearlySalaryTo,
                                  currency: jobDetails.currency.id || '',
                              },
                    },
                    jobAdTitle: jobAd.title,
                    jobAdDescription: jobAd.description,
                    tests: [], // we do not show test information in preview
                    minutesToComplete: 0, // we do not minutes to complete information in preview
                    skills,
                }}
                settings={{
                    preview: true,
                    platformUrl: '', // apply button is not shown in preview
                    websiteUrl: siteUrls.homePageUrl,
                }}
            />
        </Stack>
    );
}

export default JobAdNavigationButtons;
