// TODO: https://dev.azure.com/TalentMesh/TalentMesh/_sprints/taskboard/TalentMesh%20Team/TalentMesh/Sprint%2010?workitem=726

import { PercentileLevel } from '@talentmesh/core';
import { EducationDTO } from '../DTO/Assessments/EducationDTO';
import { LocationDetailsDTO } from '../DTO/Assessments/LocationDetailsDTO';
import { ExperienceDTO } from '../DTO/Users/ExperienceDTO';
import { TalentScore } from './TalentScore';
import ApplicantsOverviewPageDTO from '../DTO/Candidates/ApplicantsOverviewPageDTO';
import { ApplicantsPage } from './Page';
import toDateString from '../Utils/DateHelper';

export enum CandidateStatuses {
    Invited = 'Invited',
    Rejected = 'Rejected',
    Hired = 'Hired',
    AssessmentOpened = 'AssessmentOpened',
    AssessmentOngoing = 'AssessmentOngoing',
    AssessmentCompleted = 'AssessmentCompleted',
    AssessmentRetaken = 'AssessmentRetaken',
    Interviewing = 'Interviewing',
}

export enum CandidateTestMonitoringStatuses {
    Disabled = 'Disabled',
    PartialEnabled = 'PartialEnabled',
    Enabled = 'Enabled',
    NA = 'NA',
}

export enum CandidateTabs {
    Open = 'Open',
    Hired = 'Hired',
    Rejected = 'Rejected',
}

export interface ScoreDTO {
    percentileScore: number;
    percentileLevel: PercentileLevel;
}

export interface CandidateStatusDetail {
    lastUpdatedAt: string;
    status: CandidateStatuses;
}

export interface CandidateOverview {
    candidateId: string;
    name: string;
    candidateFirstName: string;
    candidateLastName: string;
    invitedAt: string;
    completedAt?: string;
    lastViewedAt?: string;
    currentStatus: CandidateStatusDetail;
    rejectedByEmail: boolean;
    talentScore: TalentScore;
    skillsPercentileScore: ScoreDTO;
    aptitudePercentileScore: ScoreDTO;
    emotionalIntelligencePercentileScore: ScoreDTO;
    personalityPercentileScore: ScoreDTO;
    candidateTestMonitoringStatus: CandidateTestMonitoringStatuses;
    candidateEmail: string;
    isFavorite: boolean;
    isInternal: boolean;
    locationDetails: LocationDetailsDTO;
    education: EducationDTO;
    experience: ExperienceDTO;
}

export const fromApplicantsOverview = (dto: ApplicantsOverviewPageDTO): ApplicantsPage<CandidateOverview> => {
    const { page, totalOpen, totalHired, totalRejected } = dto;
    const data = (page.data ? page.data : []).map((d) => {
        return {
            ...d,
            currentStatus: {
                status: d.currentStatus.status,
                lastUpdatedAt: toDateString(new Date(d.currentStatus.lastUpdatedAt)),
            } as CandidateStatusDetail,
        };
    });
    return {
        pagination: page.pagination,
        data,
        totalOpen,
        totalHired,
        totalRejected,
    };
};
