import { useState } from 'react';
import { ToasterStatus } from '@talentmesh/core';
import { CandidateStatusDTO } from '../../../../DTO/Candidates/CandidateStatusDTO';
import { CandidatesResultsContextData } from './CandidateResultsContext';
import { useAssessmentClient, useBlobClient, useCandidatesClient } from '../../../../Hooks/ClientHooks';
import { TestTypes } from '../../../../Models/Configuration';
import {
    CandidateAssessmentResults,
    DefaultCandidateAssessmentResults,
} from '../../../../Models/CandidateAssessmentResults';
import { JobCategory } from '../../../../Models/JobCategory';
import UIStrings from '../../../../Utils/UIStrings';
import { getFirstTest, getValueByTestType } from '../Utils/ResultsUtils';
import { getJobCategoryJobFunctionPair } from '../../../../Utils/JobFunctionSelectionUtils';

function useCandidatesResultsContextValue(): CandidatesResultsContextData {
    const assessmentClient = useAssessmentClient();
    const candidatesClient = useCandidatesClient();
    const blobClient = useBlobClient();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [assessmentId, setAssessmentId] = useState('');
    const [assessmentName, setAssessmentName] = useState('');
    const [candidateId, setCandidateId] = useState<string>('');
    const [results, setResults] = useState<CandidateAssessmentResults>(DefaultCandidateAssessmentResults);
    const { candidateFirstName } = results;

    const [openResetToaster, setOpenResetToaster] = useState<boolean>(false);
    const [resetToasterStatus, setResetToasterStatus] = useState<ToasterStatus>();
    const [resetToasterMessage, setResetToasterMessage] = useState<string>('');

    const [jobTitle, setJobTitle] = useState('');
    const [jobCategory, setJobCategory] = useState('');
    const [jobFunction, setJobFunction] = useState('');
    const [activeTestDetailsTab, setActiveTestDetailsTab] = useState('');

    const [openCloseAssessmentConfirm, setOpenCloseAssessmentConfirm] = useState(false);

    const setJobInfo = (resultsJobFunctionId: number, jobCategories: JobCategory[]) => {
        const { jobCategoryName, jobFunctionName } = getJobCategoryJobFunctionPair(jobCategories, resultsJobFunctionId);
        setJobTitle(`${jobCategoryName}: ${jobFunctionName}`);
        setJobCategory(jobCategoryName);
        setJobFunction(jobFunctionName);
    };

    const loadCandidateResultsAsync = async (id: string, candidate: string, jobCategories: JobCategory[]) => {
        setIsLoading(true);
        setAssessmentId(id);
        const response = await assessmentClient.getCandidateResults(id, candidate);
        setResults(response);

        const spec = await assessmentClient.getSpecificationAsync(id);
        setAssessmentName(spec.name);

        setCandidateId(candidate);
        setJobInfo(response.jobFunctionId, jobCategories);
        setActiveTestDetailsTab(getValueByTestType(getFirstTest(response)));
        setIsLoading(false);
    };

    const updateCandidateStatus = (candidateStatus: CandidateStatusDTO) => {
        setResults((prevState) => {
            const nextState = { ...prevState };
            nextState.candidateStatus = candidateStatus.candidateStatus;
            return nextState;
        });
    };

    const updateIsFavorite = (isFavorite: boolean) => {
        setResults((prevState) => {
            const nextState = { ...prevState };
            nextState.isFavorite = isFavorite;
            return nextState;
        });
    };

    const resetAssessmentAsync = async () => {
        try {
            const nextState = await candidatesClient.resetApplicantAssessmentResultAsync(assessmentId, candidateId);

            setResults(nextState);
            setActiveTestDetailsTab(getValueByTestType(TestTypes.None));
            setResetToasterStatus('success');
            setResetToasterMessage(UIStrings.successAssessmentResetToast(candidateFirstName));
        } catch {
            setResetToasterStatus('error');
            setResetToasterMessage(UIStrings.errorAssessmentResetToast(candidateFirstName));
        } finally {
            setOpenResetToaster(true);
        }
    };
    const downloadCvAsync = async () => await blobClient.downloadCvAsync(assessmentId, candidateId);

    return {
        downloadCvAsync,
        assessmentId,
        assessmentName,
        isLoading,

        candidateId,

        jobTitle,
        jobCategory,
        jobFunction,
        activeTestDetailsTab,

        results,
        updateCandidateStatus,
        updateIsFavorite,
        loadCandidateResultsAsync,
        setActiveTestDetailsTab,

        openCloseAssessmentConfirm,
        setOpenCloseAssessmentConfirm,

        resetAssessmentAsync,
        openResetToaster,
        setOpenResetToaster,
        resetToasterStatus,
        setResetToasterStatus,
        resetToasterMessage,
    };
}

export default useCandidatesResultsContextValue;
