import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { Table, TableBody, TableContainer } from '@talentmesh/core';
import { CandidateEditRow } from './CandidateEditRow';
import { CandidateViewRow } from './CandidateViewRow';
import { Candidate } from '../../../../../Models/Candidate';
import { useInviteCandidateContext } from '../../Contexts/InviteCandidateContext';

const CandidatesList = (): JSX.Element => {
    const theme = useTheme();
    const [editableCandidate, setEditableCandidate] = useState<Candidate | undefined>();
    const { candidates } = useInviteCandidateContext();
    const verticalCellSpacing = 0.625;

    return (
        <TableContainer
            sx={{
                width: 'auto',
                maxHeight: theme.spacing(31.5),
                paddingRight: candidates.length > 4 ? theme.spacing(1) : 0,
            }}
        >
            <Table
                sx={{
                    borderSpacing: theme.spacing(0, verticalCellSpacing),
                    marginTop: `-${theme.spacing(verticalCellSpacing)}`,
                }}
            >
                <TableBody>
                    {candidates.map((candidate) => {
                        if (editableCandidate && editableCandidate.id === candidate.id) {
                            return (
                                <CandidateEditRow
                                    key={candidate.email}
                                    candidate={editableCandidate}
                                    setEditableCandidate={setEditableCandidate}
                                />
                            );
                        }
                        return (
                            <CandidateViewRow
                                key={candidate.email}
                                candidate={candidate}
                                setEditableCandidate={setEditableCandidate}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CandidatesList;
