import React from 'react';
import JobDetailsStep from '../Steps/JobDetailsStep/JobDetailsStep';
import SelectTestsStep from '../Steps/SelectTests/SelectTestsStep';
import RequestInformationStep from '../Steps/RequestInformation/RequestInformationStep';
import JobAdStep from '../Steps/JobAdStep/JobAdStep';
import PostJobStep from '../Steps/PostJobStep/PostJobStep';

export interface StepMapperProps {
    activeStep: number;
    handleNext: () => void;
    handleBack: () => void;
}

export function ModifyRecruitmentStepMapper({ activeStep, handleNext, handleBack }: StepMapperProps): JSX.Element {
    return (
        <>
            {activeStep === 0 && (
                <JobDetailsStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 1 && <JobAdStep activeStep={activeStep} handleBack={handleBack} />}
        </>
    );
}

export function CreateFullRecruitmentStepMapper({ activeStep, handleNext, handleBack }: StepMapperProps): JSX.Element {
    return (
        <>
            {activeStep === 0 && (
                <JobDetailsStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 1 && <JobAdStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />}
            {activeStep === 2 && (
                <SelectTestsStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 3 && (
                <RequestInformationStep activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
            )}
            {activeStep === 4 && <PostJobStep activeStep={activeStep} handleBack={handleBack} />}
        </>
    );
}
