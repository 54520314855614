import { Guid } from 'guid-typescript';
import { DirectReportsType, WorkExperienceType } from '@talentmesh/core';
import { AssessmentSpecificationDTO } from '../DTO/Assessments/AssessmentSpecificationDTO';
import { TestTypes } from './Configuration';
import { AssessmentStatuses } from './AssessmentData';
import LocationDetails from './LocationDetails';

export interface AssessmentSpec {
    id: number;
    name: string;
    locationDetails?: LocationDetails;
    isFullyRemote: boolean;
    assessmentId: string;
    jobFunctionId: number;
    workExperience: WorkExperienceType;
    includedTests: TestTypes[];
    directReports: DirectReportsType;
    assessmentStatus: AssessmentStatuses;
}

function mapDirectReportsType(value: any): DirectReportsType {
    switch (value) {
        case 1:
            return 'None';
        case 2:
            return 'SmallGroup';
        case 3:
            return 'MediumGroup';
        case 4:
            return 'LargeGroup';
        default:
            return value;
    }
}

export function fromAssessmentSpecificationDTO2AssessmentSpec(
    dto: AssessmentSpecificationDTO,
    locationDetails?: LocationDetails,
): AssessmentSpec {
    return {
        ...dto,
        directReports: mapDirectReportsType(dto.directReports),
        locationDetails,
    };
}

export const DefaultAssessmentSpec: AssessmentSpec = {
    id: 0,
    name: '',
    locationDetails: undefined,
    isFullyRemote: false,
    assessmentId: Guid.createEmpty().toString(),
    jobFunctionId: 0,
    workExperience: 'EntryLevel',
    includedTests: [TestTypes.Skills, TestTypes.Aptitude, TestTypes.Personality],
    directReports: 'None',
    assessmentStatus: AssessmentStatuses.NonPublic,
};
