import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@talentmesh/core';
import ProfileEducation, { ProfileEducationProps } from './ProfileEducation';
import ProfileExperience, { ProfileExperienceProps } from './ProfileExperience';
import ProfileLocation, { ProfileLocationProps } from './ProfileLocation';

interface ApplicantStatusCellProps extends ProfileEducationProps, ProfileExperienceProps, ProfileLocationProps {}

function ApplicantProfileCell({ locationDetails, experience, education }: ApplicantStatusCellProps): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            sx={{
                alignItems: 'start',
                paddingRight: theme.spacing(1.25),
            }}
            spacing={0.625}
        >
            {locationDetails && <ProfileLocation locationDetails={locationDetails} />}
            {education && <ProfileEducation education={education} />}
            {experience && <ProfileExperience experience={experience} />}
        </Stack>
    );
}

export default ApplicantProfileCell;
