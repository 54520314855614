/* eslint-disable import/prefer-default-export */
import { TestTypes } from '../../../../Models/Configuration';
import UIStrings from '../../../../Utils/UIStrings';
import toDateString from '../../../../Utils/DateHelper';
import { CandidateAssessmentResults } from '../../../../Models/CandidateAssessmentResults';

function getTabHeaderByTestType(testType: TestTypes): string {
    switch (testType) {
        case TestTypes.Aptitude:
            return UIStrings.Aptitude;
        case TestTypes.EmotionalIntelligence:
            return UIStrings.EmotionalIntelligence;
        case TestTypes.Personality:
            return UIStrings.Personality;
        case TestTypes.Skills:
            return UIStrings.Skills;
        default:
            return '';
    }
}

function getValueByTestType(testType: TestTypes): string {
    switch (testType) {
        case TestTypes.None:
            return '0';
        case TestTypes.Personality:
            return '1';
        case TestTypes.Skills:
            return '2';
        case TestTypes.Aptitude:
            return '3';
        case TestTypes.EmotionalIntelligence:
            return '4';
        default:
            return '';
    }
}

function getFirstTest(result: CandidateAssessmentResults): TestTypes {
    if (result.personalityScore != null) {
        return TestTypes.Personality;
    }

    if (result.aptitudeScore != null) {
        return TestTypes.Aptitude;
    }

    if (result.skillsScore != null) {
        return TestTypes.Skills;
    }

    if (result.emotionalIntelligenceScore != null) {
        return TestTypes.EmotionalIntelligence;
    }

    return TestTypes.None;
}

function getSnapshotCounterString(current: number, total: number): string {
    return `${UIStrings.Snapshot} ${current} ${UIStrings.Of} ${total}`;
}

function getSnapshotDateString(date: Date): string {
    const time = date.toLocaleTimeString('default', {
        hour: '2-digit',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    });
    const calendarDate = toDateString(date);
    return `${calendarDate} • ${time}`;
}

function getResultsHeaderByTestType(testType: TestTypes): string {
    switch (testType) {
        case TestTypes.Personality:
            return UIStrings.PersonalityTestResultsFor;
        case TestTypes.Aptitude:
            return UIStrings.AptitudeTestResultsFor;
        case TestTypes.Skills:
            return UIStrings.SkillsTestResultsFor;
        case TestTypes.EmotionalIntelligence:
            return UIStrings.EmotionalIntelligenceTestResultsFor;
        default:
            throw new Error(`Test type ${testType} not supported`);
    }
}

const isTestCompleted = (results: CandidateAssessmentResults, testType: TestTypes) => {
    switch (testType) {
        case TestTypes.Personality:
            return results.personalityScore != null;
        case TestTypes.Aptitude:
            return results.aptitudeScore != null;
        case TestTypes.Skills:
            return results.skillsScore != null;
        case TestTypes.EmotionalIntelligence:
            return results.emotionalIntelligenceScore != null;
        default:
            return false;
    }
};

export {
    getTabHeaderByTestType,
    getValueByTestType,
    getSnapshotCounterString,
    getSnapshotDateString,
    getResultsHeaderByTestType,
    getFirstTest,
    isTestCompleted,
};
