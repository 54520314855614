import { DirectReportsType, WorkExperienceType } from '@talentmesh/core';
import { AssessmentSpec } from '../../Models/AssessmentSpec';
import { TestTypes } from '../../Models/Configuration';
import { AssessmentStatuses } from '../../Models/AssessmentData';

export interface AssessmentSpecificationDTO {
    id: number;
    name: string;
    locationId?: string;
    jobLocation?: string;
    isFullyRemote: boolean;
    jobFunctionId: number;
    assessmentId: string;
    workExperience: WorkExperienceType;
    includedTests: TestTypes[];
    directReports: DirectReportsType;
    assessmentStatus: AssessmentStatuses;
}

export const DefaultAssessmentSpecificationDTO: AssessmentSpecificationDTO = {
    id: 0,
    name: '',
    locationId: undefined,
    jobLocation: undefined,
    isFullyRemote: false,
    jobFunctionId: 0,
    assessmentId: '',
    workExperience: 'EntryLevel',
    includedTests: [],
    directReports: 'None',
    assessmentStatus: AssessmentStatuses.NonPublic,
};

export function fromAssessmentSpec(spec: AssessmentSpec): AssessmentSpecificationDTO {
    return {
        ...spec,
        locationId: spec.locationDetails?.locationId,
        jobLocation: spec.locationDetails?.description,
    };
}
