import { useTheme } from '@mui/material';
import React from 'react';
import { Box, Divider, Typography } from '@talentmesh/core';
import { useUserContext } from '../../../Context/UserContext';

export const ProfileMenuHeaderDividerHeight = 0.1;

// this file contains div instead of Box is intentional because @talentmesh/core does not
// support forwardRef as of the time of writing this, please see https://dev.azure.com/TalentMesh/TalentMesh/_git/recruiter-app/pullRequest/3729#1692091931
const ProfileMenuHeader = React.forwardRef<HTMLDivElement>((_, ref) => {
    const theme = useTheme();
    const { user } = useUserContext();

    return (
        <div ref={ref}>
            <Box py={theme.spacing(1.25)} px={theme.spacing(1)}>
                <Typography
                    variant="body2"
                    fontWeight={theme.typography.fontWeightBold}
                    color={theme.palette.text.menuTitle}
                >
                    {user.firstName}
                </Typography>
                {user.role && (
                    <Typography variant="caption" color={theme.palette.text.menuTitle}>
                        {user.role}
                    </Typography>
                )}
            </Box>
            <Divider sx={{ borderBottomWidth: theme.spacing(ProfileMenuHeaderDividerHeight) }} />
        </div>
    );
});

export default ProfileMenuHeader;
