import { createContext, useContext } from 'react';
import { ToasterStatus } from '@talentmesh/core';
import { CandidateAssessmentResults } from '../../../../Models/CandidateAssessmentResults';
import { CandidateStatusDTO } from '../../../../DTO/Candidates/CandidateStatusDTO';
import { JobCategory } from '../../../../Models/JobCategory';

export interface CandidatesResultsContextData {
    isLoading: boolean;
    assessmentId: string;
    assessmentName: string;

    candidateId: string;

    jobTitle: string;
    jobCategory: string;
    jobFunction: string;
    activeTestDetailsTab: string;

    results: CandidateAssessmentResults;
    updateCandidateStatus: (candidateStatus: CandidateStatusDTO) => void;
    updateIsFavorite: (isFavorite: boolean) => void;
    loadCandidateResultsAsync: (id: string, candidate: string, jobCategories: JobCategory[]) => void;
    setActiveTestDetailsTab: (tabValue: string) => void;

    openCloseAssessmentConfirm: boolean;
    setOpenCloseAssessmentConfirm: (value: boolean) => void;

    resetAssessmentAsync: () => Promise<void>;
    openResetToaster: boolean;
    setOpenResetToaster: (value: boolean) => void;
    resetToasterStatus: ToasterStatus | undefined;
    setResetToasterStatus: (value: ToasterStatus | undefined) => void;
    resetToasterMessage: string;
    downloadCvAsync: () => Promise<Blob>;
}

export const CandidatesResultsContext = createContext<CandidatesResultsContextData | undefined>(undefined);

export function useCandidatesResultsContext() {
    const context = useContext(CandidatesResultsContext);
    if (!context) {
        throw new Error('useCandidatesResultsContext must be used within the CandidatesResultsContext.Provider');
    }
    return context;
}
