import { useTheme } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { Stack, Box, Button, Search } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { DialogEnum, useActionDialogContext } from '../../../../Dialogs/ATS/Context/ActionDialogContext';
import { mapFromCandidateOverviewToCandidateDialogModel } from '../../../../Dialogs/Dialogs.Utils';
import { useCandidatesOverviewContext } from '../../Contexts/CandidatesOverviewContext';
import AdvancedFiltersToolbar from './AdvancedFiltersToolbar';
import CandidatesListToolTip from './CandidatesListToolTip';
import ToolbarFilterButton from './ToolbarFilterButton';

function ToolBar(): JSX.Element {
    const theme = useTheme();

    const { showDialog } = useActionDialogContext();
    const { assessmentId, selected, setSelected, testTypes, tabValue, searchByName, openFilter, search } =
        useCandidatesOverviewContext();

    const rejectDialogHandle = useCallback(() => {
        showDialog(
            selected.map((candidate) => mapFromCandidateOverviewToCandidateDialogModel(assessmentId, candidate)),
            DialogEnum.Reject,
            testTypes,
        );
        setSelected([]);
    }, [selected]);

    // Search's onSearch triggers searchByName which will reset pageNumber on first render
    const onSearch = (query: string) => searchByName(query);

    return (
        <Stack
            direction="row"
            sx={{
                paddingInline: 1,
                cursor: 'auto',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: theme.spacing(8),
                backgroundColor: theme.palette.grey[100],
                borderBottom: `1px solid ${theme.palette.border.main}`,
            }}
            data-cy="gridToolbar"
        >
            <Box>
                {selected.length > 0 && (
                    <CandidatesListToolTip title={UIStrings.RejectApplicants}>
                        <Button
                            color="error"
                            variant="contained"
                            size="small"
                            onClick={rejectDialogHandle}
                            data-cy="ToolbarGroupRemove"
                        >
                            {UIStrings.RejectByCount(selected.length)}
                        </Button>
                    </CandidatesListToolTip>
                )}
            </Box>
            <Stack direction="row" spacing={1.25}>
                {openFilter && <AdvancedFiltersToolbar />}
                <Search
                    value={search}
                    data-cy="ToolbarSearch"
                    placeholder={UIStrings.FilterByApplicantsName}
                    onSearch={onSearch}
                    InputProps={{ sx: { maxHeight: theme.spacing(5) } }}
                />
                {tabValue === 'Open' && <ToolbarFilterButton />}
            </Stack>
        </Stack>
    );
}

export default ToolBar;
