import { assessmentsApiUrl } from '../AppSettings';
import { CreateAssessmentDTO } from '../DTO/Assessments/CreateAssessmentDTO';
import { DefaultRecruiterAssessmentDTO, RecruiterAssessmentDTO } from '../DTO/Assessments/RecruiterAssessmentDTO';
import ApplicantsOverviewPageDTO from '../DTO/Candidates/ApplicantsOverviewPageDTO';
import { AdvancedFilterDTO } from '../DTO/Results/AdvancedFilterDTO';
import { DefaultVersionDto, VersionDto } from '../DTO/VersionDto';
import { AssessmentStatuses, mapAssessmentStatus } from '../Models/AssessmentData';
import { AssessmentOverview } from '../Models/AssessmentOverview';
import { AssessmentSpec, DefaultAssessmentSpec } from '../Models/AssessmentSpec';
import {
    CandidateAssessmentResults,
    DefaultCandidateAssessmentResults,
    mapCandidateAssessmentResultsDTO2Model,
} from '../Models/CandidateAssessmentResults';
import { CandidateOverview, CandidateStatuses, fromApplicantsOverview } from '../Models/CandidateOverview';
import {
    ApplicantsPage,
    EmptyApplicantsPage,
    AssessmentOverviewPage,
    EmptyAssessmentOverviewPage,
} from '../Models/Page';
import CandidateFilterByEnum from '../Pages/Candidates/Overview/Models/CandidateFilterByEnum';
import CandidateSortByEnum from '../Pages/Candidates/Overview/Models/CandidateSortByEnum';
import getEnumKeyByEnumValue from '../Utils/getEnumKeyByEnumValue';
import { get, getFile, post, put, toJson } from '../Utils/HTTP/HttpUtils';
import { CandidateAssessmentResultsDTO } from '../DTO/Assessments/CandidateAssessmentResultsDTO';
import ClientBase from './ClientBase';
import { DefaultInvitedCandidatesDTO, InvitedCandidatesDTO } from '../DTO/Users/InvitedCandidatesDTO';
import AssessmentOverviewPageDTO from '../DTO/Assessments/RecruitmentsOverviewPageDTO';
import { mapCreatedAssessmentsState } from '../Models/CreatedAssessmentsState';
import { UpdateRecruitmentDTO } from '../DTO/Assessments/UpdateRecruitmentDTO';

export default class AssessmentClient extends ClientBase {
    async getSpecificationAsync(assessmentId: string): Promise<AssessmentSpec> {
        const response = await get<AssessmentSpec>({
            accessToken: await this.getAccessTokenSilently(),
            path: this.addHost(`assessments/${assessmentId}/specification`),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultAssessmentSpec;
    }

    async getAssessmentOverviewsAsync(
        offset: number,
        limit: number,
        statuses: AssessmentStatuses[],
        search: string,
        companyId: string,
    ): Promise<AssessmentOverviewPage<AssessmentOverview>> {
        const response = await get<AssessmentOverviewPageDTO>({
            path: this.addHost(
                `assessments/company/${companyId}/assessments-overview/?${this.buildAssessmentQuery(
                    offset,
                    limit,
                    statuses,
                    search,
                )}`,
            ),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            const data = (response.body.data ? response.body.data : []).map((d) => {
                return {
                    ...d,
                    status: mapAssessmentStatus(d.status),
                };
            });
            return {
                pagination: response.body.pagination,
                data,
                createdAssessmentState: mapCreatedAssessmentsState(response.body.createdAssessmentState),
            };
        } else {
            return EmptyAssessmentOverviewPage;
        }
    }

    buildAssessmentQuery(offset: number, limit: number, statuses: AssessmentStatuses[], search: string): string {
        const query = new URLSearchParams({
            offset: offset.toString(),
            limit: limit.toString(),
        });

        statuses.map((status) => query.append('statuses', status));

        if (search?.length > 1) {
            query.append('search', search);
        }

        return query.toString();
    }

    async getCandidateOverviewsAsync(
        assessmentId: string,
        offset: number,
        limit: number,
        filterBy: CandidateFilterByEnum[],
        search: string,
        status?: CandidateStatuses,
        sortBy?: CandidateSortByEnum,
        advancedFilters?: AdvancedFilterDTO[],
    ): Promise<ApplicantsPage<CandidateOverview>> {
        const strategy = toJson(advancedFilters || []);
        const response = await post<ApplicantsOverviewPageDTO>({
            path: this.addHost(
                `assessments/${assessmentId}/candidates-overview?${this.buildCandidateQuery(
                    offset,
                    limit,
                    filterBy,
                    search,
                    status,
                    sortBy,
                )}`,
            ),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });

        if (response.ok && response.body) {
            return fromApplicantsOverview(response.body);
        } else {
            return EmptyApplicantsPage;
        }
    }

    buildCandidateQuery(
        offset: number,
        limit: number,
        filterBys: CandidateFilterByEnum[],
        search: string,
        status?: CandidateStatuses,
        sortBy?: CandidateSortByEnum,
    ): string {
        const query = new URLSearchParams({
            offset: offset.toString(),
            limit: limit.toString(),
        });

        filterBys.forEach((filterBy) => {
            const filterKey = getEnumKeyByEnumValue(CandidateFilterByEnum, filterBy);
            query.append('filterBy', filterKey);
        });

        if (search?.length > 1) {
            query.append('search', search);
        }

        if (status) {
            const statusKey = getEnumKeyByEnumValue(CandidateStatuses, status);
            query.append('status', statusKey);
        }

        if (sortBy !== undefined) {
            query.append('sortBy', CandidateSortByEnum[sortBy]);
        }

        return query.toString();
    }

    async getCandidatesAsync(assessmentId: string): Promise<InvitedCandidatesDTO> {
        const response = await get<InvitedCandidatesDTO>({
            path: this.addHost(`assessments/${assessmentId}/candidates`),
            accessToken: await this.getAccessTokenSilently(),
        });

        if (response.ok && response.body) {
            return response.body || [];
        } else {
            return DefaultInvitedCandidatesDTO;
        }
    }

    async createAssessmentAsync(createDto: CreateAssessmentDTO, companyId: string): Promise<RecruiterAssessmentDTO> {
        const strategy = toJson(createDto);
        const response = await post<RecruiterAssessmentDTO>({
            path: this.addHost(`assessments/company/${companyId}`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });

        if (response.ok && response.body) {
            return response.body;
        } else {
            return DefaultRecruiterAssessmentDTO;
        }
    }

    async updateRecruitmentAsync(updateDto: UpdateRecruitmentDTO): Promise<void> {
        const strategy = toJson(updateDto);
        await put({
            path: this.addHost(`assessments`),
            accessToken: await this.getAccessTokenSilently(),
            ...strategy,
        });
    }

    async publishRecruitmentAsync(recruitmentId: string): Promise<void> {
        const path = this.addHost(`assessments/${recruitmentId}/publish`);
        const accessToken = await this.getAccessTokenSilently();

        await post({
            path,
            accessToken,
        });
    }

    async closeAssessmentAsync(assessmentId: string, rejectCandidates: boolean, sendEmail: boolean): Promise<void> {
        const path = this.addHost(`assessments/close`);
        const accessToken = await this.getAccessTokenSilently();
        const strategy = toJson({ assessmentId, rejectCandidates, sendEmail });

        await post({
            path,
            accessToken,
            ...strategy,
        });
    }

    async getCandidateResults(assessmentId: string, candidateId: string): Promise<CandidateAssessmentResults> {
        const response = await get<CandidateAssessmentResultsDTO>({
            path: this.addHost(`assessments/${assessmentId}/results?candidateId=${candidateId}`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return mapCandidateAssessmentResultsDTO2Model(response.body);
        }

        return DefaultCandidateAssessmentResults;
    }

    async getCandidateResultsPdfReport(assessmentId: string, candidateId: string): Promise<Blob> {
        const encodedCandidateId = encodeURIComponent(candidateId);
        return getFile({
            path: `${assessmentsApiUrl}reports/assessments/${assessmentId}/results/pdf?candidateId=${encodedCandidateId}`,
            accessToken: await this.getAccessTokenSilently(),
        });
    }

    async getVersionAsync(): Promise<VersionDto> {
        const response = await get<VersionDto>({
            path: this.addHost(`version`),
            accessToken: await this.getAccessTokenSilently(),
        });
        if (response.ok && response.body) {
            return response.body;
        }
        return DefaultVersionDto;
    }
}
