import React from 'react';
import { LocationOnIcon } from '@talentmesh/icons';
import ProfileChip from './ProfileChip';
import ProfileDetailLabel from './ProfileDetailLabel';
import { CandidateOverview } from '../../../../../Models/CandidateOverview';
import UIStrings from '../../../../../Utils/UIStrings';

export interface ProfileLocationProps extends Pick<CandidateOverview, 'locationDetails'> {}

function ProfileLocation({ locationDetails }: ProfileLocationProps): JSX.Element {
    const { description } = locationDetails;

    return (
        <ProfileChip
            hideOnLabelOverflow
            testLabel={UIStrings.CurrentLocation}
            label={description}
            icon={<LocationOnIcon />}
            hoverContent={<ProfileDetailLabel name={UIStrings.CurrentLocation} value={description} />}
        />
    );
}

export default ProfileLocation;
