import React from 'react';
import TabByTestTypeSwitcher from './Tabs/TabByTestTypeSwitcher';
import CTMonitoringPanelByTestType from './TestMonitoring/CTMonitoringPanelByTestType';
import WideTabPanel from '../../../../Components/Tab/WideTabPanel';
import DetailsTab from './Tabs/DetailsTab';
import { mainTestTypesRenderingOrder } from '../../Utils/Utils';
import { useCandidatesResultsContext } from '../Contexts/CandidateResultsContext';
import { getValueByTestType, isTestCompleted } from '../Utils/ResultsUtils';
import { TestTypes } from '../../../../Models/Configuration';

function ResultsTabContent(): JSX.Element {
    const { results, activeTestDetailsTab } = useCandidatesResultsContext();

    return (
        <>
            {mainTestTypesRenderingOrder.map((testType) => {
                if (isTestCompleted(results, testType)) {
                    return (
                        <WideTabPanel key={testType} tabKey={getValueByTestType(testType)} value={activeTestDetailsTab}>
                            <TabByTestTypeSwitcher testType={testType} />
                            <CTMonitoringPanelByTestType testType={testType} {...results} />
                        </WideTabPanel>
                    );
                }
                return null;
            })}
            <WideTabPanel tabKey={getValueByTestType(TestTypes.None)} value={activeTestDetailsTab}>
                <DetailsTab />
            </WideTabPanel>
        </>
    );
}

export default ResultsTabContent;
