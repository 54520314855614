import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import { LoadingButton } from '@talentmesh/core';
import UIStrings from '../../../../../Utils/UIStrings';
import { useInviteCandidateContext } from '../../Contexts/InviteCandidateContext';
import { useInvitationPageContext } from '../../Contexts/InvitationPageContext';

function SendInvitationsButton(): JSX.Element {
    const theme = useTheme();
    const [sending, setSending] = useState<boolean>(false);
    const { allowDialog } = useInvitationPageContext();
    const { dirtyCount, inviteCandidatesAsync } = useInviteCandidateContext();

    const handleApplicantInvite = async () => {
        setSending(true);
        await inviteCandidatesAsync(allowDialog);
        setSending(false);
    };

    return (
        <LoadingButton
            variant="contained"
            sx={{
                width: 'auto',
                alignSelf: 'flex-end',
                height: theme.spacing(6.5),
                marginTop: theme.spacing(1.25),
            }}
            onClick={handleApplicantInvite}
            disabled={dirtyCount < 1}
            loading={sending}
        >
            {UIStrings.SendInvitationButton}
        </LoadingButton>
    );
}

export default SendInvitationsButton;
