import { useAuth0 } from '@auth0/auth0-react';
import ResultsClient from '../Clients/ResultsClient';
import SnapshotsClient from '../Clients/SnapshotsClient';
import AssessmentClient from '../Clients/AssessmentClient';
import BenchmarkClient from '../Clients/BenchmarkClient';
import BillingInfoClient from '../Clients/BillingInfoClient';
import CandidatesClient from '../Clients/CandidatesClient';
import HubspotClient from '../Clients/HubspotClient';
import JobTestInfoClient from '../Clients/JobTestInfoClient';
import PaymentMethodClient from '../Clients/PaymentMethodClient';
import SettingsClient from '../Clients/SettingsClient';
import UsersClient from '../Clients/UsersClient';
import BlobClient from '../Clients/BlobClient';
import CompanyClient from '../Clients/CompanyClient';
import AutocompleteOptionsClient from '../Clients/AutocompleteOptionsClient';
import JobAdClient from '../Clients/JobAdClient';
import InvitationClient from '../Clients/InvitationClient';
import PaymentClient from '../Clients/PaymentClient';
import CareerClient from '../Clients/CareerClient';
import QuestionsConfigurationClient from '../Clients/QuestionsConfigurationClient';

const useBlobClient = (): BlobClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new BlobClient(getAccessTokenSilently);
};

const useAssessmentClient = (): AssessmentClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new AssessmentClient(getAccessTokenSilently);
};

const useSettingsClient = (): SettingsClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new SettingsClient(getAccessTokenSilently);
};

const useCandidatesClient = (): CandidatesClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new CandidatesClient(getAccessTokenSilently);
};

const useUsersClient = (): UsersClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new UsersClient(getAccessTokenSilently);
};

const useSnapshotsClient = (): SnapshotsClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new SnapshotsClient(getAccessTokenSilently);
};

const useBillingInfoClient = (): BillingInfoClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new BillingInfoClient(getAccessTokenSilently);
};

const useBenchmarkClient = (): BenchmarkClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new BenchmarkClient(getAccessTokenSilently);
};

const usePaymentClient = (): PaymentClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new PaymentClient(getAccessTokenSilently);
};

const usePaymentMethodClient = (): PaymentMethodClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new PaymentMethodClient(getAccessTokenSilently);
};

const useJobTestInfoClient = (): JobTestInfoClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new JobTestInfoClient(getAccessTokenSilently);
};

const useCompanyClient = (): CompanyClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new CompanyClient(getAccessTokenSilently);
};

const useHubspotClient = (): HubspotClient => {
    return new HubspotClient();
};

const useAutocompleteOptionsClient = (): AutocompleteOptionsClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new AutocompleteOptionsClient(getAccessTokenSilently);
};

const useJobAdClient = (): JobAdClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new JobAdClient(getAccessTokenSilently);
};

const useResultsClient = (): ResultsClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new ResultsClient(getAccessTokenSilently);
};

const useInvitationClient = (): InvitationClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new InvitationClient(getAccessTokenSilently);
};

const useCareerClient = (): CareerClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new CareerClient(getAccessTokenSilently);
};

const useQuestionsConfigurationClient = (): QuestionsConfigurationClient => {
    const { getAccessTokenSilently } = useAuth0();
    return new QuestionsConfigurationClient(getAccessTokenSilently);
};

export {
    useBlobClient,
    useAssessmentClient,
    useSettingsClient,
    useCandidatesClient,
    useUsersClient,
    useSnapshotsClient,
    useBillingInfoClient,
    useBenchmarkClient,
    usePaymentClient,
    usePaymentMethodClient,
    useJobTestInfoClient,
    useCompanyClient,
    useHubspotClient,
    useAutocompleteOptionsClient,
    useJobAdClient,
    useResultsClient,
    useInvitationClient,
    useCareerClient,
    useQuestionsConfigurationClient,
};
