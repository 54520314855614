/* eslint-disable @typescript-eslint/lines-between-class-members */
import { Message } from 'yup/lib/types';
import UIStrings from './UIStrings';

export default class YupValidationErrors {
    static readonly Required = 'Required';
    static readonly EmailValidationError = UIStrings.PleaseEnterValidEmailAddress;
    static readonly MaxSymbolsValidationError: Message<{ max: number }> = ({ max }) => `Must not exceed ${max} symbols`;
    static readonly JobTitleMaxSymbolsValidationError: Message<{ max: number }> = ({ max }) =>
        `Job title should not exceed ${max} characters`;
    static readonly NegativeValueError = 'Negative numbers are not allowed. Please enter a positive value.';
    static readonly IncorrectSalaryLimitsError =
        'The minimum salary cannot be greater than the maximum salary. Please adjust your input.';
    static readonly EmptyFieldError = 'This field cannot be empty. Please enter a valid value.';
    static readonly InvalidInputError = 'Invalid input. Please enter a valid format.';
    static readonly SalaryExceedsSystemLimitError = 'Salary exceeds system limit. Please enter a valid amount.';
    static readonly JobAdTitleMaxSymbolsValidationError: Message<{ max: number }> = ({ max }) =>
        `Job ad title should not exceed ${max} characters`;
    static readonly PleaseAddAtLeastOneSkillRequiredError = (skillType: string) =>
        `Please add at least one ${skillType} skill or toggle off the section.`;
    static readonly JobDescriptionRequiredError = 'Please enter job description.';
    static readonly JobDescriptionLimitError = 'Please limit description.';
    static readonly CompanyDescriptionLimitError = 'Please limit company description.';
    static readonly GreaterThanZero = 'Please enter a value greater than 0.';
    static readonly AmountCannotExceed = (max: string) => `Annual fixed amount cannot exceed ${max} `;
    static readonly BonusCannotExceed = (max: string) => `Annual target bonus cannot exceed ${max} `;
}
