import React, { ReactNode, useMemo, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, TMTooltip, TooltipPaper } from '@talentmesh/core';

interface ProfileCellProps {
    label: string;
    icon: ReactNode;
    hoverContent: ReactNode;
    testLabel: string;
    hideOnLabelOverflow?: boolean;
}

function ProfileChip({
    label,
    icon,
    hoverContent,
    testLabel,
    hideOnLabelOverflow = false,
}: ProfileCellProps): JSX.Element {
    const theme = useTheme();
    const textRef = useRef<HTMLSpanElement>(null);
    const isTextOverflowing = useMemo(() => {
        if (textRef.current) {
            return hideOnLabelOverflow ? textRef.current.scrollWidth > textRef.current.clientWidth : true;
        }

        return true;
    }, []);

    return (
        <TMTooltip
            disableInteractive
            title={
                isTextOverflowing && (
                    <TooltipPaper>
                        <Typography variant="body2" color="secondary">
                            {hoverContent}
                        </Typography>
                    </TooltipPaper>
                )
            }
        >
            <Stack
                data-cy={`ApplicantProfileChip-${testLabel.replace(' ', '')}`}
                direction="row"
                sx={{
                    backgroundColor: theme.palette.hue.primary.h50,
                    color: theme.palette.hue.primary.h900,
                    borderRadius: theme.shape.smallBorderRadius,
                    padding: theme.spacing(0.375, 0.5),
                    alignItems: 'center',
                    maxWidth: theme.spacing(32.375),
                }}
            >
                {icon}
                <Typography
                    ref={textRef}
                    variant="caption"
                    sx={{
                        paddingX: theme.spacing(0.75),
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    {label}
                </Typography>
            </Stack>
        </TMTooltip>
    );
}

export default ProfileChip;
